<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary dark width="300">
      <v-list class="evo-navigation-list">
        <v-list-item>
          <v-list-item-content>
            <v-img @click="goTo('/')" src="@/assets/footer-logo.png" max-width="214px" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <EvoNavigationItems
        class="evo-navigation-items"
        :showPrivacy="false"
        :companyItems="companyItems"
        :documentsItems="documentsItems"
        :loginItems="loginItems"
      />
    </v-navigation-drawer>

    <v-app-bar
      app
      :flat="flat"
      :elevation="flat ? 0 : 1"
      light
      class="evo-nav-wrapper"
      :class="{
        'evo-expand': flat,
        'evo-nav': !isScroll,
        'evo-nav-main': isMain && !isScroll,
        'evo-nav-scroll': isScroll,
        'evo-nav-wrapper--is-mobile': $vuetify.breakpoint.mobile
      }"
      v-scroll="onScroll"
    >
      <v-toolbar-title>
        <v-container>
          <v-row @click="goTo('/')" style="cursor: pointer">
            <v-col class="evo-logo-wrapper">
              <img v-show="!isScroll" src="@/assets/evonet-logo.svg" style="max-width: 54px" alt="evonet-logo" />
              <img v-show="isScroll" src="@/assets/evonet-logo-fill.svg" style="max-width: 54px" alt="evonet-logo-fill" />
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.mobile">
        <div class="nav-icon-wrapper">
          <div class="nav-icon" v-for="n in 3" :key="n"></div>
        </div>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center" v-else>
        <!-- Home -->
        <router-link to="/" class="router-link mr-4" exact>
          <v-btn class="evo-px-btn-hover" :ripple="false" text>
            <span class="evo-nav-item evo-px-nav text-none">
              {{ $t('main') }}
            </span>
          </v-btn>
        </router-link>
        <!-- solutions -->
        <v-menu offset-y bottom :nudge-left="10" open-on-hover transition="slide-y-transition" content-class="dropdown-nav" v-model="productsOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              text
              :color="activeProductRouteColor"
              class="mr-2 evo-nav-item evo-px-nav-menu-item evo-px-nav text-none evo-px-drop-btn-hover"
              :class="{ isScroll }"
            >
              {{ $t('solutions') }}
              <v-icon right :class="{ 'evo-menu-open': productsOpen }"> $vuetify.icons.mdiChevronDown </v-icon>
            </v-btn>
          </template>
          <v-card class="d-flex">
            <div class="evo-px-nav-card">
              <router-link to="/merchant-services" class="router-link">
                <div class="link-btn evo-px-text-16 evo-opensans-regular">
                  {{ $t('merchant_services') }}
                </div>
              </router-link>
              <div class="list-wrap">
                <template v-for="(item, index) in homeMerchantItems">
                  <template v-if="item.path">
                    <router-link :to="item.path" class="router-link" :key="index">
                      <div class="list-item evo-px-text-14 evo-opensans-bold">
                        {{ $t(item.title) }}
                      </div>
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="list-item list-item-title evo-px-text-14 evo-opensans-regular" :key="index">
                      {{ $t(item.title) }}
                    </div>
                  </template>
                </template>
              </div>
            </div>
            <div class="evo-px-nav-card black">
              <router-link to="/wallet-network" class="router-link">
                <div class="link-btn evo-px-text-16 evo-opensans-regular">
                  {{ $t('home.wallet_network') }}
                </div>
              </router-link>
              <div class="list-wrap">
                <template v-for="(item, index) in homeItems">
                  <template v-if="item.path">
                    <router-link :to="item.path" class="router-link" :key="index">
                      <div class="list-item evo-px-text-14 evo-opensans-bold">
                        {{ $t(item.title) }}
                      </div>
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="list-item list-item-title evo-px-text-14 evo-opensans-regular" :key="index">
                      {{ $t(item.title) }}
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </v-card>
        </v-menu>
        <!-- Company -->
        <v-menu offset-y open-on-hover transition="slide-y-transition" bottom v-model="companyOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              text
              :color="activeCompanyRouteColor"
              class="mr-2 evo-nav-item evo-px-nav-menu-item evo-px-nav text-none evo-px-drop-btn-hover"
              :class="{ isScroll }"
            >
              {{ $t('company') }}
              <v-icon right :class="{ 'evo-menu-open': companyOpen }"> $vuetify.icons.mdiChevronDown </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in companyItems" :key="index" link>
              <v-list-item-title>
                <router-link :to="item.path" class="router-link">
                  <v-btn text class="evo-drop-btn-hover" :ripple="false">
                    <span class="mr-2 evo-nav-item evo-px-nav text-none">
                      {{ $t(item.title) }}
                    </span>
                  </v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Documents -->
        <v-menu offset-y open-on-hover transition="slide-y-transition" bottom v-model="documentsOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              text
              class="mr-2 evo-nav-item evo-px-nav-menu-item evo-px-nav text-none evo-px-drop-btn-hover"
              :class="{ isScroll }"
            >
              {{ $t('documents') }}
              <v-icon right :class="{ 'evo-menu-open': documentsOpen }"> $vuetify.icons.mdiChevronDown </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in documentsItems" :key="index" link @click="goHref(item.href)">
              <v-list-item-title>
                <template v-if="item.href">
                  <v-btn text class="evo-drop-btn-hover" :ripple="false">
                    <span class="mr-2 evo-nav-item evo-px-nav text-none">
                      {{ $t(item.title) }}
                    </span>
                  </v-btn>
                </template>
                <template v-else>
                  <router-link :to="item.path" class="router-link">
                    <v-btn text class="evo-drop-btn-hover" :ripple="false">
                      <span class="mr-2 evo-nav-item evo-px-nav text-none">
                        {{ $t(item.title) }}
                      </span>
                    </v-btn>
                  </router-link>
                </template>
              </v-list-item-title>
              <v-list-item-icon v-if="item.icon">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- login -->
        <v-menu offset-y bottom open-on-hover transition="slide-y-transition" content-class="dropdown-nav" v-model="loginOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              text
              class="mr-6 evo-nav-item evo-px-nav-menu-item evo-px-nav text-none evo-px-drop-btn-hover"
              :class="{ isScroll }"
              style="margin-left: 60px"
            >
              {{ $t('login') }}
              <v-icon right :class="{ 'evo-menu-open': loginOpen }"> $vuetify.icons.mdiChevronDown </v-icon>
            </v-btn>
          </template>
          <v-card class="d-flex flex-column">
            <div v-for="(item, index) in loginItems" :key="index" class="evo-px-nav-card" :class="{ black: item.black }">
              <div class="evo-link-item" @click="goHref(item.href)">
                <span class="evo-px-text-15 evo-opensans-bold">
                  {{ $t(item.title) }}
                </span>
                <v-icon v-text="item.icon"></v-icon>
              </div>
              <div class="evo-link-item-desc evo-px-text-14 evo-opensans-regular">
                {{ $t(item.desc) }}
              </div>
            </div>
          </v-card>
        </v-menu>
        <!-- Touch -->
        <router-link to="/contact-us" class="router-link">
          <v-btn rounded class="text-none evo-get-touch evo-px-nav" :class="{ isScroll }">
            {{ $t('get_in_touch') }}
          </v-btn>
        </router-link>
        <div v-if="false" class="d-flex align-center" style="margin-left: 60px">
          <v-btn
            small
            outlined
            color="#969696"
            class="text-none evo-opensans-regular evo-letter-spacing"
            @click="onOpenAPIs"
            style="margin-right: 25px; background: white"
          >
            {{ $t('get_open_apis') }}
            <v-icon right>$vuetify.icons.mdiChevronRight</v-icon>
          </v-btn>
          <v-btn
            small
            outlined
            color="#969696"
            class="text-none evo-opensans-regular evo-letter-spacing"
            @click="onLogin"
            style="margin-right: 5px; background: white"
          >
            {{ $t('login') }}
            <v-icon right>$vuetify.icons.mdiChevronRight</v-icon>
          </v-btn>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import EvoNavigationItems from './NavigationItems.vue';
export default {
  name: 'EvoNavigation',
  props: {
    flat: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    activeCompanyRouteColor() {
      const routes = ['Partners', 'AboutUs', 'ContactUs'];
      const activeColor = 'rgba(255, 255, 255)';
      return routes.indexOf(this.$route.name) > -1 ? activeColor : '';
    },
    activeProductRouteColor() {
      const routes = ['Home', 'MerchantServices', 'PaymentMethods', 'FraudProtect', 'HomeMerchant', 'Payment', 'Transfer', 'Debit', 'FXRate'];
      const activeColor = 'rgba(255, 255, 255)';
      return routes.indexOf(this.$route.name) > -1 ? activeColor : '';
    },
    isScroll() {
      return this.scrollY > 64;
    },
    isMain() {
      return this.$route.name === 'Main';
    }
  },

  components: { EvoNavigationItems },

  data: () => ({
    drawer: null,
    isXs: false,
    scrollY: 0,
    productsOpen: false,
    companyOpen: false,
    documentsOpen: false,
    loginOpen: false,
    companyItems: [
      {
        title: 'about_us',
        path: '/about-us'
      },
      {
        title: 'partnership.title',
        path: '/partnership'
      },
      {
        title: 'contact_us',
        path: '/contact-us'
      },
      {
        title: 'news.title',
        path: '/newsroom'
      }
    ],
    homeMerchantItems: [
      {
        title: 'product'
      },
      {
        title: 'online_merchant_acquiring',
        path: '/online-merchant-acquiring'
      },
      {
        title: 'feature'
      },
      {
        title: 'payment_methods.title',
        path: '/payment-methods'
      },
      {
        title: 'fraud_protection',
        path: '/fraud-protection'
      }
    ],
    homeItems: [
      {
        title: 'product'
      },
      {
        title: 'cross_border_e_payment',
        path: '/cross-border-e-payment'
      },
      {
        title: 'inter_wallet_remittance',
        path: '/inter-wallet-remittance'
      },
      {
        title: 'feature'
      },
      {
        title: 'fx_rate_convertor',
        path: '/fx-rate-convertor'
      }
    ],
    documentsItems: [
      {
        title: 'developer_center',
        href: 'https://developer.evonetonline.com/',
        icon: '$vuetify.icons.mdiLoginVariant'
      },
      {
        title: 'company_policy',
        path: '/company-policy'
      }
    ],
    loginItems: [
      {
        black: false,
        title: 'merchant_services_hub',
        href: ' https://hub.evonetonline.com/passport/login',
        icon: '$vuetify.icons.mdiLoginVariant',
        desc: 'merchant_services_hub_desc'
      },
      {
        black: true,
        title: 'global_wallet_portal',
        href: 'https://www.evonetonline.com/passport/login',
        icon: '$vuetify.icons.mdiLoginVariant',
        desc: 'global_wallet_portal_desc'
      }
    ]
  }),

  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    goTo(link) {
      this.$router.push(link).catch(() => {});
    },
    onOpenAPIs() {
      window.location.href = 'https://developer.evonetonline.com/';
    },
    onLogin() {
      window.location.href = 'https://www.evonetonline.com/passport/login';
    },
    onScroll() {
      this.scrollY = window.scrollY;
    },
    // 外链跳转
    goHref(href) {
      if (!href) return;
      window.open(href, '_blank');
    }
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  }
};
</script>

<style lang="scss" scoped>
$text-color: #222a41;
::v-deep(.evo-nav-scroll.elevation-1) {
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0 0 10px 1px rgb(255 255 255 / 20%) !important;
}
.v-list {
  padding: 0;
}
.v-toolbar {
  transition: 0.5s, background-color 0s !important;
}

.evo-nav-wrapper {
  padding: 0 126px;

  .evo-px-nav {
    font-size: 14px !important;
  }

  .evo-nav-item {
    font-weight: bold;
    line-height: 14px;
  }

  .evo-px-nav-menu-item {
    height: 80px !important;
    &.isScroll {
      height: 64px !important;
    }
  }

  .evo-menu-open {
    transform: rotate(-180deg);
  }

  .evo-logo-wrapper {
    display: flex;
    align-items: center;
    & > img {
      width: 38px;
    }
  }

  .evo-get-touch {
    @include whiteblue_btn;
    min-width: 128px;
    &.isScroll {
      @include lightblue_btn;
      background-color: #3b43f2;
    }
  }

  .evo-px-logo {
    font-size: 7px;
    margin-left: -50px;
    transform: scale(0.6);
    color: #3b5999;
  }

  .evo-language {
    font-weight: 600;
    color: $title_color;
  }

  .evo-px-lan {
    font-size: 10px;
  }
}

.evo-expand {
  height: 80px !important;
  padding-top: 10px;
}

.v-toolbar {
  transition: 0.5s;
}

::v-deep(.evo-px-btn-hover) {
  &.theme--light.v-btn:hover::before {
    opacity: 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-radius: 2px;
    background: #ffffff;
    transition: height 0.2s ease;
  }

  &:hover::after {
    height: 3px;
  }
}

::v-deep(.evo-drop-btn-hover) {
  letter-spacing: normal;
  &.theme--light.v-btn:hover::before {
    opacity: 0;
  }
}

::v-deep(.evo-px-drop-btn-hover) {
  &.theme--light.v-btn:hover::before {
    opacity: 0;
  }
  &::after {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 22px;
    height: 0;
    border-radius: 2px;
    background: #ffffff;
    transition: height 0.2s ease;
  }

  &:hover::after {
    height: 3px;
  }
}

.v-app-bar--is-scrolled {
  .evo-px-btn-hover::after {
    background: $text-color;
  }
  .evo-px-drop-btn-hover::after {
    bottom: 14px;
    background: $text-color;
  }
}

::v-deep(.router-link .v-btn) {
  letter-spacing: initial !important;
}

::v-deep(.v-list .router-link.router-link-active .evo-nav-item) {
  color: inherit !important;
}

::v-deep(.evo-nav-item.v-btn) {
  letter-spacing: initial !important;
}

.evo-menu-content {
  box-shadow: 0px 2px 9px 0px rgba(179, 192, 231, 0.32) !important;
}

.evo-nav-scroll {
  background-color: #fff !important;
  border: 0 transparent !important;
  .evo-nav-item,
  .v-icon {
    color: $text-color !important;
  }

  .router-link.router-link-active .evo-nav-item {
    color: $text-color !important;
  }
}

.evo-nav {
  background-color: #373ed7 !important;
  box-shadow: none;
  .evo-nav-item,
  .v-icon {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .router-link.router-link-active .evo-nav-item {
    color: #ffffff !important;
  }
}

.evo-nav-main {
  background-color: #3a42e9 !important;
}

.dropdown-nav {
  .evo-px-nav-card {
    width: 320px;
    border-radius: 0 !important;
    padding: 32px;
    color: $text-color;
    background: #ffffff;

    .v-icon {
      color: $text-color;
    }

    .link-btn {
      display: inline-block;
      min-width: 237px;
      padding: 0 10px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 16px;
      cursor: pointer;
      color: #b4b4b4;
      border: 1px solid #979797;
    }

    .list-wrap {
      display: flex;
      flex-direction: column;
      margin: 17px 0 0 8px;

      .list-item {
        height: 20px;
        line-height: 20px;
        color: $text-color;
        cursor: pointer;

        &.list-item-title {
          font-weight: 600;
          color: #b4b4b4;
          cursor: text;
        }
      }
      & > * + * {
        margin-top: 10px;
      }
    }

    &.black {
      color: #ffffff;
      background: $text-color;
      .v-icon {
        color: #ffffff;
      }
      .link-btn {
        color: #ffffff;
        border: 1px solid #d0d2d7;
      }

      .list-wrap {
        .list-item {
          color: #ffffff;

          &.list-item-title {
            color: #666a76;
          }
        }
      }
    }

    .evo-link-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .link-title {
        line-height: 26px;
      }
    }

    .evo-link-item-desc {
      margin-top: 14px;
      padding-left: 8px;
      padding-right: 4px;
      min-height: 48px;
      font-weight: 600;
      color: #b4b4b4;
      line-height: 18px;
    }
  }
}
// 移动端样式
.evo-nav-wrapper--is-mobile {
  padding: 0;
  .evo-logo-wrapper {
    padding: 0 0 0 4px;
    & > img {
      width: 80px;
    }
  }

  .nav-icon-wrapper {
    display: flex;
    flex-direction: column;
    .nav-icon {
      position: relative;
      width: 25px;
      height: 7px;
      background: #fff;
      border-radius: 24px;
      & + .nav-icon {
        margin-top: 11px;
      }
    }
  }
  ::v-deep(.v-toolbar__content) {
    padding-left: 48px;
    padding-right: 52px;
  }
}
.evo-nav-wrapper--is-mobile.evo-nav-main {
  background-color: #373ed7 !important;
}
.evo-nav-scroll .nav-icon-wrapper .nav-icon {
  background: #424242;
}
.evo-navigation-items {
  padding: 0 32px;
}
.evo-navigation-list {
  padding: 20px 0;
}
</style>
