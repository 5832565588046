import * as dayjs from "dayjs";

export const YYYY_MM_DD_TODAY = new Date(
  Date.now() - new Date().getTimezoneOffset() * 60000
)
  .toISOString()
  .substring(0, 10);

export function objectToQueryString(obj) {
  var str = [];

  for (var p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
}

export function errorHandler(err, vm, info) {
  // console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  console.log(info);
  console.log(err);
}

export const TODAY = dayjs().startOf("day").format("YYYYMMDD");

export const LAST_7_DAYS = dayjs()
  .startOf("day")
  .subtract(7, "day")
  .format("YYYYMMDD");

export const LAST_30_DAYS = dayjs()
  .startOf("day")
  .subtract(30, "day")
  .format("YYYYMMDD");

export function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function genRandomNumbers(min, max, count) {
  const arr = [];
  while (arr.length < count) {
    var r = Math.floor(Math.random() * (max - min)) + min;
    if (arr.indexOf(r) === -1) arr.push(r);
  }
  return arr;
}

export function sortMediaNews(a, b) {
  // sort by date use dayjs
  if (dayjs(b.date, 'MMMM Do, YYYY').isAfter(dayjs(a.date, 'MMMM Do, YYYY'))) {
    return -1;
  }

  return 1;
}