<template>
  <div class="section-1 evo-ph-176">
    <!-- 圆点背景 -->
    <div class="top-bg-wrappr">
      <div class="dot-container">
        <div class="dot-row" :class="[`dot-row-${n}`]" v-for="n in 14" :key="n">
          <div class="dot-item"></div>
        </div>
      </div>
    </div>
    <!-- 云层背景 -->
    <div class="bottom-bg"></div>
    <v-row>
      <v-col class="evo-left-wrapper" cols="12" md="5" sm="12">
        <div class="evo-main-title evo-text-34 animate__animated animate__50 animate__fadeInUp">
          {{ $t('main_entry.digital_payment') }}
        </div>
        <div class="evo-main-title evo-text-34 animate__animated animate__50 animate__fadeInUp">
          {{ $t('main_entry.borderless') }}
        </div>
        <div class="evo-main-title evo-text-34 animate__animated animate__50 animate__fadeInUp mb-8">
          {{ $t('main_entry.opportunity') }}
        </div>
        <div class="evo-main-sub-title evo-text-16 animate__animated animate__100 animate__fadeInUp evo-opensans-regular">
          {{ $t('main_entry.sub_title') }}
        </div>
        <router-link to="contact-us" class="router-link">
          <v-btn rounded :x-large="$vuetify.breakpoint.lgAndUp" class="text-none evo-get-started animate__animated animate__150 animate__fadeInUp">
            {{ $t('main_entry.get_started') }}
          </v-btn>
        </router-link>
      </v-col>
      <v-col class="evo-right-wrapper" cols="12" md="7" sm="12">
        <div id="evo-globe-wrapper"></div>
      </v-col>
    </v-row>

    <v-row class="evo-entry-pane">
      <v-col cols="12" md="12" sm="12" data-aos="fade-up">
        <div class="evo-title evo-text-29 text-center evo-opensans-regular">
          {{ $t('main_entry.expand_business_1') }}<br />
          {{ $t('main_entry.expand_business_2') }}
        </div>
        <v-row class="evo-entry-row" justify="space-between">
          <v-col cols="12" sm="12" md="6">
            <v-row class="evo-entry-info">
              <v-col cols="12" sm="12" md="4">
                <img src="@/assets/main/section-1-left.png" alt="section-1-left" />
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <div class="evo-main-entry-title evo-opensans-regular">
                  {{ $t('main_entry.digital_merchant_acquiring_service') }}
                </div>
                <div class="evo-main-entry-desc evo-opensans-regular">
                  {{ $t('main_entry.capture_online') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row class="evo-entry-info">
              <v-col cols="12" sm="12" md="4">
                <img src="@/assets/main/section-1-right.png" alt="section-1-right" />
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <div class="evo-main-entry-title evo-opensans-regular">
                  {{ $t('main_entry.cross_border_payment_remittance') }}
                </div>
                <div class="evo-main-entry-desc evo-opensans-regular">
                  {{ $t('main_entry.join_evonet') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="evo-entry-btn-wrapper">
          <v-col>
            <router-link to="/merchant-services" class="router-link">
              <v-btn rounded x-large color="default" class="text-none evo-entry-btn evo-opensans-regular">
                {{ $t('main_entry.merchant_btn') }}
                <img loading="lazy" class="ml-4" src="@/assets/arrow_right.svg" alt="arrow_left" />
              </v-btn>
            </router-link>
          </v-col>
          <v-col>
            <router-link to="/wallet-network" class="router-link">
              <v-btn rounded x-large color="default" class="text-none evo-entry-btn evo-opensans-regular">
                {{ $t('main_entry.evonet_btn') }}
                <img loading="lazy" class="ml-4" src="@/assets/arrow_right.svg" alt="arrow_right" />
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- <div id="evo-globe-wrapper"></div> -->
    <img
      loading="lazy"
      v-for="(item, index) in countries"
      :key="index"
      :src="item.icon"
      :style="{ top: item.top, right: item.right }"
      class="evo-country-img"
      @mouseover="hoverCountry(item.location)"
      :alt="item.name"
    />
  </div>
</template>
<script>
import { mainSection1Mixin } from '@/mixins/main.js';

export default {
  name: 'EvoMainSectionOne',
  mixins: [mainSection1Mixin]
};
</script>
<style lang="scss" scoped>
$png: '~@/assets/main/dot.svg';
$sizeList: (1 1 1.5 1.5 2 2.5 3 3.5 4.5 5.5 6.5 7.5 8.5 9.5 10.5 11.5 12.5 13 14 15 15 16 17 17 17),
  (1 1 1.5 1.5 2 2.5 3 3.5 4.5 5.5 6.5 7.5 8.5 9.5 10.5 11.5 12.5 13 14 15 15 16 17 17 17),
  (1 1 1.5 1.5 2 2.5 3 3.5 4.5 5.5 6.5 7.5 8.5 9.5 10.5 11.5 12.5 13 14 15 15 16 17 17 17),
  (1.5 1.5 2 2 2.5 3 3.5 4 5 6 7 8 9 10 11 12 12.5 13.5 14 15 15 16 17 17 17),
  (1.5 1.5 2 2 2.5 3 3.5 4 5 6 7 8 9 10 11 12 12.5 13.5 14 15 15 16 17 17 17),
  (2 2 2.5 3 3.5 4 5 6 7 8 9 10 11 12 12.5 13 13.5 14 15 15 15 16 16 16 16),
  (2 2 2.5 3 3.5 4 5 6 7 8 9 10 11 12 12.5 13 13.5 14 15 15 15 15.5 15.5 15.5 15.5),
  (2 2 2.5 3 3.5 4 5 6 7 8 9 10 11 12 12.5 13 13.5 14 15 15 15 15 15 15 15),
  (2.5 2.5 3 3 3.5 4 5 6 7 8 9 10 11 11.5 12 12.5 13.5 14 15 15 15 14 14 14 14),
  (3 3 3.5 3.5 3.5 4 4.5 4.5 5.5 6 6.5 7 7.5 7.5 8.5 10.5 10.5 11 11 12.5 12.5 12 12 12 12),
  (3 3 3.5 3.5 3.5 4 4.5 4.5 5.5 6 6.5 7 7.5 7.5 8.5 10.5 10.5 11 11 11.5 11.5 11 11 11 11),
  (3.5 3.5 4 4 4.5 4.5 5.5 6 6.5 6.5 7 7.5 8 8.5 9 9 10 10 11 11 10.5 10.5 10.5 10.5 10.5),
  (3.5 3.5 4 4 4.5 4.5 5.5 6 6.5 6.5 7 7.5 8 8.5 9 9.5 10 10 10.5 10.5 10.5 10 10 10 10),
  (4 4 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5 4.5);
.dot-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 0 0 8px;
  max-height: 76vh;
  .dot-row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 40px;
    & + .dot-row-new {
      margin-top: 8px;
    }
    .dot-item {
      position: relative;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  @mixin multiBox($row) {
    $shadow_list: ();
    @for $i from 1 through length($row) {
      $size: nth($row, $i);
      $string: calc(42px * #{$i - 1}) 0 0 #{$size * -1}px #364fed;
      $shadow_list: append($shadow_list, $string, comma);
    }
    box-shadow: $shadow_list;
  }

  @for $i from 1 through length($sizeList) {
    $row: nth($sizeList, $i);
    .dot-row-#{$i} {
      mask: linear-gradient(-45deg, transparent, #fff);
      .dot-item {
        @include multiBox($row);
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          border-radius: 50%;
          width: #{36 - nth($row, 1) * 2}px;
          height: #{36 - nth($row, 1) * 2}px;
          background-color: #364fed;
        }
      }
    }
  }
}

.section-1 {
  position: relative;
  padding-right: 100px !important;
  background-image: linear-gradient(to right, #3b43f2, #373ed2);
  height: 120vh;
  color: #ffffff;
  border-bottom-left-radius: 12%;
  border-bottom-right-radius: 12%;

  .top-bg-wrappr {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 12%;
    border-bottom-right-radius: 12%;
    overflow: hidden;
  }

  .bottom-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('~@/assets/main/s1-bg-1.svg');
    border-bottom-left-radius: 12%;
    border-bottom-right-radius: 12%;
  }

  > .row {
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .evo-left-wrapper {
    margin-top: 108px;

    .evo-main-title {
      font-weight: bold;
      color: #ffffff;
      line-height: 44px;
      white-space: nowrap;
    }

    .evo-main-sub-title {
      font-weight: normal;
      color: #ffffff;
      line-height: 24px;
      margin-right: 8px;
    }

    .evo-get-started {
      @include whiteblue_btn;
      margin-top: 48px;
      min-width: 128px;
    }
  }

  .evo-entry-pane {
    position: absolute;
    top: 100vh;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: auto;
    z-index: 3;

    & > div {
      background: #ffffff;
      box-shadow: 0px 17px 68px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      padding: 20px;
    }

    .evo-title {
      line-height: 31px;
      margin-bottom: 32px;
      color: #222a41;
    }

    .evo-entry-row {
      margin-bottom: 16px;

      & > div:nth-of-type(1) {
        padding-right: 16px;
      }

      & > div:nth-of-type(2) {
        padding-left: 16px;
      }
    }

    .evo-entry-info {
      align-items: flex-start !important;

      & img {
        width: 100%;
        height: auto;
      }

      .evo-main-entry-title {
        font-size: 14px;
        color: #222a41;
        margin-bottom: 8px;
        white-space: pre-wrap;
      }

      .evo-main-entry-desc {
        font-size: 10px;
        color: #6d7783;
        padding-right: 4px;
      }
    }

    .evo-entry-btn-wrapper {
      & > div:nth-of-type(1) {
        padding-right: 16px;
      }

      & > div:nth-of-type(2) {
        padding-left: 16px;
      }
    }

    .evo-entry-btn {
      @include whiteblue_btn;
      color: #3b43f2;
      width: 320px;
      width: 100%;
    }
  }

  .evo-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
  }

  #evo-globe-wrapper {
    margin-top: 16px;
    margin-left: 16px;
    z-index: 2;
  }

  .evo-country-img {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 3;
    font-size: 80px;
  }
}

.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate__50 {
  animation-delay: 50ms;
}

.animate__100 {
  animation-delay: 100ms;
}

.animate__150 {
  animation-delay: 150ms;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInUp {
  animation-name: slideInUp;
}
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInDown {
  animation-name: slideInDown;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp {
  animation-name: fadeInUp;
}
</style>
