<template>
  <div class="evo-footer-wrapper" :class="{ 'mt-self': !isPageContactUs }">
    <!-- 波浪背景 -->
    <div class="evo-bg"></div>

    <div class="evo-footer-content evo-mobile">
      <!-- pane -->
      <div class="evo-contact-pane-wrapper" v-if="!isPageContactUs">
        <div class="evo-contact-pane">
          <div class="evo-contact-us">
            <img loading="lazy" class="evo-logo" src="@/assets/footer-contact-pane-mobile.png" />
            <router-link to="/contact-us" class="router-link">
              <v-btn rounded x-large class="text-none evo-contact-btn">
                {{ $t('home.learn_more') }}
              </v-btn>
            </router-link>
          </div>
          <div class="evo-contact-content">
            <div class="evo-contact-title evo-text-54 evo-opensans-extrabold">
              {{ $t('contact_title') }}
            </div>
            <div class="evo-contact-sub-title evo-text-34 evo-opensans-regular">
              {{ $t('contact_sub_title') }}
            </div>
          </div>
        </div>
        <div class="evo-contact-pane-bg"></div>
      </div>
      <!-- logo -->
      <div class="evo-logo-wrapper">
        <img loading="lazy" class="evo-logo" src="@/assets/evonet-logo.svg" />
        <div class="evo-logo-desc-wrapper evo-text-26">
          {{ $t('global') }}<br />
          {{ $t('wallet_network_aggregator') }}<br />
          {{ $t('shared_distributed_and_open_network') }}<br />
        </div>
      </div>
      <!-- category / privacy-->
      <EvoNavigationItems class="evo-navigation-items" :companyItems="companyItems" :documentsItems="documentsItems" />
      <!-- contact -->
      <div class="evo-contact-wrapper evo-text-36 evo-opensans-regular">
        <div class="evo-find-us">{{ $t('find_us') }}</div>
        <div class="evo-email">{{ 'contact@evonetglobal.com' }}</div>
        <!-- <div class="evo-phone">{{ '(65) 62791630' }}</div> -->
        <div class="evo-follow-us">{{ $t('follow_us') }}</div>
        <div class="evo-mdi-icon">
          <a href="https://www.facebook.com/Evonet-Global-Pte-Ltd-110368611698374" target="_blank" class="text-decoration-none">
            <v-icon color="white"> $vuetify.icons.mdiFacebook </v-icon>
          </a>
          <a href="https://twitter.com/EvonetGlobal" target="_blank" class="text-decoration-none">
            <v-icon color="white"> $vuetify.icons.mdiTwitter </v-icon>
          </a>
        </div>
      </div>
      <!-- copyright -->
      <div class="evo-copyright evo-text-25"> ©️ {{ year }} EVONET Global. All rights reserved. </div>
    </div>

    <!-- 半圆1 -->
    <div class="half-circle-1-wrapper">
      <div class="half-circle"></div>
    </div>
    <!-- 半圆2 -->
    <div class="half-circle-2-wrapper">
      <div class="half-circle"></div>
    </div>
  </div>
</template>

<script>
import EvoNavigationItems from '@/components/NavigationItems.vue';
import { footerMixin } from '@/mixins/components/footer.js';
export default {
  name: 'EvoMobileFooter',
  mixins: [footerMixin],
  components: { EvoNavigationItems }
};
</script>

<style lang="scss" scoped>
$color-white-600: rgba(
  $color: #ffffff,
  $alpha: 0.6
);
// pane向外延伸的高度
$outer-height: 555px;
// 波浪背景高度
$evo-bg-height: 246px;
.evo-footer-wrapper {
  position: relative;
  color: #ffffff;
  background: #373ed7;
  margin-top: $evo-bg-height;
  &.mt-self {
    margin-top: $outer-height + $evo-bg-height;
  }

  .evo-bg {
    position: absolute;
    top: -$evo-bg-height + 2px;
    left: 0;
    width: 100vw;
    height: $evo-bg-height;
    background-image: url('~@/assets/wave.svg');
  }

  .evo-footer-content {
    position: relative;
    margin: auto;
  }

  .evo-contact-pane-wrapper {
    position: relative;
    margin: (-$outer-height - $evo-bg-height) auto 110px;
  }

  .evo-contact-pane {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    width: 678px;
    height: 1125px;
    background: #ffffff;
    box-shadow: 25px 74px 181px 0px rgba(49, 89, 211, 0.12);
    border-radius: 89px;

    .evo-contact-us {
      width: 504px;
      height: 504px;
      background: #ecf2ff;
      border-radius: 60px;
      margin-bottom: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 14px;

      .evo-logo {
        width: 221px;
        height: auto;
        margin-bottom: 74px;
      }

      .evo-contact-btn {
        @include lightblue_btn;
        width: 279px;
        height: 91px;
        font-size: 29px;
      }
    }

    .evo-contact-content {
      max-width: 504px;
      .evo-contact-title {
        font-weight: bold;
        color: #000000;
        line-height: 63px;
        margin-bottom: 54px;
      }

      .evo-contact-sub-title {
        min-height: 317px;
        font-weight: bold;
        line-height: 63px;
        color: #a0a3a8;
      }
    }
  }

  .evo-contact-pane-bg {
    position: absolute;
    content: ' ';
    bottom: -36px;
    left: 50%;
    transform: translateX(-50%);
    width: 609px;
    height: 1107px;
    background: #fff;
    box-shadow: 25px 74px 181px 0px rgba(49, 89, 211, 0.12);
    border-radius: 89px;
    opacity: 0.5;
  }

  .evo-logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .evo-logo {
      width: 123px;
      height: auto;
    }

    .evo-logo-desc-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 522px;
      line-height: 34px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .evo-contact-wrapper {
    margin-bottom: 33px;

    .evo-find-us,
    .evo-follow-us {
      color: $color-white-600;
      line-height: 47px;
      font-weight: normal;
    }

    .evo-email,
    .evo-phone {
      font-weight: 600;
      line-height: 47px;
    }

    .evo-find-us {
      margin-bottom: 5px;
    }

    .evo-email {
      margin-bottom: 45px;
    }

    .evo-mdi-icon {
      padding-top: 20px;
      a + a {
        margin-left: 62px;
      }
    }
  }

  .evo-copyright {
    margin-bottom: 20px;
    line-height: 49px;
  }

  .half-circle-1-wrapper {
    position: absolute;
    top: 84px;
    right: 0;
    width: 306px;
    height: 775px;
    overflow: hidden;
    pointer-events: none;
    .half-circle {
      $radius: 398.35px;
      position: absolute;
      top: -10.85px;
      left: 0;
      width: 2 * $radius;
      height: 2 * $radius;
      border-radius: 50%;
      background: #ffffff;
      opacity: 0.05;
    }
  }

  &.mt-self .half-circle-1-wrapper {
    top: 84px - $evo-bg-height;
  }

  .half-circle-2-wrapper {
    visibility: hidden;
    position: absolute;
    bottom: 210px;
    left: 0;
    width: 487px;
    height: 775px;
    overflow: hidden;
    pointer-events: none;
    .half-circle {
      $radius: 387.5px;
      position: absolute;
      top: 0;
      right: 0;
      width: 2 * $radius;
      height: 2 * $radius;
      border-radius: 50%;
      background: #ffffff;
      opacity: 0.05;
    }
  }

  &.mt-self .half-circle-2-wrapper {
    visibility: visible;
  }
}
</style>
