<template>
  <div class="evo-main">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMMainSectionOne />
      <EvoMMainSectionTwo />
    </template>
    <template v-else>
      <EvoMainSectionOne />
      <EvoMainSectionTwo class="evo-s2" />
    </template>
  </div>
</template>
<script>
import EvoMainSectionOne from "@/components/main_sections/Section_1.vue";
import EvoMainSectionTwo from "@/components/main_sections/Section_2.vue";

// mobile imports
import EvoMMainSectionOne from "@/mobile/components/main_sections/MSection_1.vue";
import EvoMMainSectionTwo from "@/mobile/components/main_sections/MSection_2.vue";
export default {
  name: "EvoMain",
  components: {
    EvoMainSectionOne,
    EvoMainSectionTwo,
    EvoMMainSectionOne,
    EvoMMainSectionTwo,
  },
};
</script>
<style lang="scss" scoped>
.evo-main {
  background: #fafafa;
  position: relative;

  .evo-s2 {
    padding-top: 324px;
  }
}
</style>
