import { UniformsUtils, ShaderMaterial, BackSide, AdditiveBlending, Mesh, SphereGeometry } from 'three';

function createAtmosphere() {
    const SHADERS = {
        'atmosphere': {
            uniforms: {},
            vertexShader: [
                'varying vec3 vNormal;',
                'void main() {',
                'vNormal = normalize( normalMatrix * normal );',
                'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
                '}'
            ].join('\n'),
            fragmentShader: [
                'varying vec3 vNormal;',
                'void main() {',
                'float intensity = pow( 0.8 - dot( vNormal, vec3( 0, 0, 1.0 ) ), 12.0 );',
                'gl_FragColor = vec4( 0.618, 0.625, 1., 1 ) * intensity;',
                '}'
            ].join('\n')
        }
    };

    let geometry = new SphereGeometry(106, 40, 30);

    let shader = SHADERS['atmosphere'];
    let uniforms = UniformsUtils.clone(shader.uniforms);

    let material = new ShaderMaterial({
        uniforms: uniforms,
        vertexShader: shader.vertexShader,
        fragmentShader: shader.fragmentShader,
        side: BackSide,
        blending: AdditiveBlending,
        transparent: true
    });

    let mesh = new Mesh(geometry, material);
    mesh.scale.set(1.3, 1.3, 1.3);
    mesh.position.set(-6, 6, -368);

    return mesh;
}

export { createAtmosphere };