const detectDeviceType = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
        ? 'Mobile'
        : 'Desktop';

const isMobile = () => detectDeviceType() === 'Mobile';

const aspect = 1.2;
const cameraZ = 300;
const canvasWidth = () => isMobile() ? window.innerWidth : window.innerWidth / 1.8;
const canvasHeight = () => canvasWidth() / aspect;

export { isMobile, aspect, cameraZ, canvasWidth, canvasHeight };