export const mediaNews = {
  // img: only needed for markdown reference images
  name: "newsroom",
  items: [
    {
      title: "news.title_1",
      subtitle: "news.desc_1",
      img: "/newsroom_images/news1.png",
      smallImg: require("@/assets/news/news1-small.png"),
      date: "August 3rd, 2022",
      link: "/newsroom/1",
      linkText: "read_more_upper"
    },
    {
      title: "news.title_2",
      subtitle: "news.desc_2",
      img: "/newsroom_images/news2.png",
      smallImg: require("@/assets/news/news2-small.png"),
      date: "July 1st, 2022",
      link: "/newsroom/2",
      linkText: "read_more_upper"
    },
    {
      title: "news.title_3",
      subtitle: "news.desc_3",
      img: "/newsroom_images/news3.png",
      smallImg: require("@/assets/news/news3-small.png"),
      date: "October 1st, 2021",
      link: "/newsroom/3",
      linkText: "read_more_upper"
    },
    {
      title: "news.title_4",
      subtitle: "news.desc_4",
      img: "/newsroom_images/news4.png",
      smallImg: require("@/assets/news/news4-small.png"),
      date: "December 1st, 2020",
      link: "/newsroom/4",
      linkText: "read_more_upper"
    }
  ]
};