import { createCamera } from './components/camera';
import { createRenderer } from './systems/renderer';
import { createScene } from './components/scene';
import { Loop } from './systems/loop';
import { createControls } from './systems/controls';
import { createLights } from './components/lights';
import { Resizer } from './systems/resizer';
import { Globe } from './components/globe';
import { pointOfView } from './systems/utils';
import { createAtmosphere } from './components/atmosphere';

let camera;
let controls;
let renderer;
let scene;
let loop;
let globe;
let atmosphere;

class World {
    constructor() {
        this.init();
    }

    init() {
        renderer = createRenderer();
        scene = createScene();
        camera = createCamera();

        loop = new Loop(camera, scene, renderer);
        controls = createControls(camera, renderer.domElement);
        controls.update();
        loop.updatables.push(controls);

        const { ambientLight, dLight, dLight1, dLight2 } = createLights();
        atmosphere = createAtmosphere();
        camera.add(ambientLight, dLight, dLight1, dLight2);
        setTimeout(() => {
            camera.add(atmosphere);
        }, 1000);

        globe = new Globe();
        globe.init();
        loop.updatables.push(globe.instance);

        scene.add(camera, globe.instance);

        pointOfView(camera, controls, globe.instance, { lat: 22.3193, lng: 114.1694 }, 1000); // China HongKong

        const resizer = new Resizer(camera, renderer);
    }

    pointOfView(geoCoords = {}, transitionDuration = 0) {
        pointOfView(camera, controls, globe.instance, geoCoords, transitionDuration);
    }

    appendTo(container) {
        container.append(renderer.domElement);
    }

    render() {
        renderer.render(scene, camera);
    }

    start() {
        loop.start();
    }

    stop() {
        loop.stop();
    }
}

export { World };