import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/i18n/i18n";
import vuetify from "./plugins/vuetify";

import "./assets/styles/text.scss";
import "./assets/styles/spacing.scss";
import "./assets/styles/index.scss";

import "./scss/fonts.scss";

import "flag-icons/css/flag-icons.css";
import { errorHandler } from "@/helpers/Utils.js";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  once: true,
});

Vue.config.productionTip = false;
Vue.config.errorHandler = errorHandler;
Vue.prototype.$evoData = {
  ccy1: "USD",
  ccy2: "SGD",
  currencies: [],
};

new Vue({
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
