<template>
  <div class="section-1">
    <div class="evo-bg-s1"></div>
    <div class="evo-mobile">
      <v-row class="evo-row evo-mb-60" align="center" justify="center">
        <v-col class="evo-left-wrapper" cols="12">
          <div class="evo-main-title evo-text-66">
            {{ $t('main_entry.digital_payment') }}
          </div>
          <div class="evo-main-title evo-text-66">
            {{ $t('main_entry.borderless') }}
          </div>
          <div class="evo-main-title evo-text-66 mb-8">
            {{ $t('main_entry.opportunity') }}
          </div>
          <div class="evo-main-sub-title evo-text-33 evo-opensans-regular">
            {{ $t('main_entry.sub_title') }}
          </div>
          <router-link to="contact-us" class="router-link">
            <v-btn rounded x-large class="text-none evo-get-started">
              <span class="evo-text-29">{{ $t('main_entry.get_started') }}</span>
            </v-btn>
          </router-link>
        </v-col>
      </v-row>

      <v-row class="evo-row" align="center" justify="center">
        <v-col class="evo-right-wrapper" cols="12">
          <div id="evo-globe-wrapper"></div>
        </v-col>
      </v-row>

      <v-row class="evo-entry-pane">
        <v-col cols="12">
          <div class="evo-title evo-text-34 text-center">
            {{ $t('main_entry.expand_business_1') }}<br />
            {{ $t('main_entry.expand_business_2') }}
          </div>
          <v-row class="evo-entry-row" justify="space-between">
            <v-col cols="12">
              <v-row class="evo-entry-info">
                <v-col cols="5">
                  <img src="@/assets/main/section-1-left.png" alt="section-1-left" />
                </v-col>
                <v-col cols="7">
                  <div class="evo-main-entry-title evo-opensans-regular">
                    {{ $t('main_entry.digital_merchant_acquiring_service') }}
                  </div>
                  <div class="evo-main-entry-desc evo-opensans-regular">
                    {{ $t('main_entry.capture_online') }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="evo-entry-btn-wrapper">
            <v-col>
              <router-link to="/merchant-services" class="router-link">
                <v-btn rounded x-large color="default" class="text-none evo-entry-btn evo-opensans-regular">
                  <span class="evo-text-26">{{ $t('main_entry.merchant_btn') }}</span>
                  <img loading="lazy" class="ml-4" src="@/assets/arrow_right.svg" alt="arrow_left" />
                </v-btn>
              </router-link>
            </v-col>
          </v-row>

          <v-row class="evo-entry-row" justify="space-between">
            <v-col cols="12">
              <v-row class="evo-entry-info">
                <v-col cols="5">
                  <img src="@/assets/main/section-1-right.png" alt="section-1-right" />
                </v-col>
                <v-col cols="7">
                  <div class="evo-main-entry-title evo-opensans-regular">
                    {{ $t('main_entry.cross_border_payment_remittance_mobile') }}
                  </div>
                  <div class="evo-main-entry-desc evo-opensans-regular">
                    {{ $t('main_entry.join_evonet') }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="evo-entry-btn-wrapper">
            <v-col>
              <router-link to="/wallet-network" class="router-link">
                <v-btn rounded x-large color="default" class="text-none evo-entry-btn evo-opensans-regular">
                  <span class="evo-text-26">{{ $t('main_entry.evonet_btn') }}</span>
                  <img loading="lazy" class="ml-4" src="@/assets/arrow_right.svg" alt="arrow_right" />
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <img
        loading="lazy"
        v-for="(item, index) in countries"
        :key="index"
        :src="item.icon"
        :style="{ top: item.top, right: item.right }"
        class="evo-country-img"
        :class="{ 'evo-country-img-mobile': $vuetify.breakpoint.mobile }"
        @mouseover="hoverCountry(item.location)"
        :alt="item.name"
      />
    </div>
  </div>
</template>
<script>
import { mainSection1Mixin } from '@/mixins/main.js';

export default {
  name: 'EvoMMainSectionOne',
  mixins: [mainSection1Mixin]
};
</script>
<style lang="scss" scoped>
.section-1 {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  color: #ffffff;

  .evo-bg-s1 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 124vh;
    background-color: linear-gradient(to right, #3b43f2, #373ed2);
    background-image: url('~@/assets/main/s1-bg-mobile.svg');
    background-size: cover;
    background-position: bottom;
    z-index: 0;
  }

  > .row {
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .evo-left-wrapper {
    margin-top: 108px;
    z-index: 1;

    .evo-main-title {
      font-weight: bold;
      color: #ffffff;
      white-space: nowrap;
      text-align: center;
    }

    .evo-main-sub-title {
      font-weight: normal;
      color: #ffffff;
      text-align: center;
    }

    .router-link {
      margin-left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      .evo-get-started {
        @include whiteblue_btn;
        margin-top: 48px;
        min-width: 280px;
      }
    }
  }

  .evo-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  .evo-entry-pane {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    margin-left: 50%;
    transform: translateX(-50%);

    & > div {
      background: #ffffff;
      box-shadow: 0px 17px 68px 0px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      padding: 20px;
    }

    .evo-title {
      line-height: 36px;
      margin-top: 32px;
      margin-bottom: 64px;
      color: #222a41;
    }

    .evo-entry-row {
      margin-bottom: 16px;
      padding: 16px 24px;

      & > div:nth-of-type(1) {
        padding-right: 16px;
      }

      & > div:nth-of-type(2) {
        padding-left: 16px;
      }
    }

    .evo-entry-info {
      align-items: flex-start !important;

      & img {
        width: 238px;
        height: auto;
      }

      .evo-main-entry-title {
        font-size: 32px;
        color: #222a41;
        margin-top: -8px;
        margin-bottom: 8px;
        white-space: pre-line;
      }

      .evo-main-entry-desc {
        font-size: 24px;
        color: #6d7783;
        // padding-right: 4px;
      }
    }

    .evo-entry-btn-wrapper {
      margin-bottom: 64px;
      & > div {
        padding: 0 48px;
      }

      .evo-entry-btn {
        @include whiteblue_btn;
        color: #3b43f2;
        width: 100%;
        min-width: 280px;
      }
    }
  }

  .evo-country-img {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 3;
    font-size: 80px;
  }

  .evo-country-img-mobile {
    width: 40px;
    height: 40px;
  }
}
</style>
