import { world } from '@/components/world/evoworld.js';
import { debounce } from '@/helpers/Utils.js';
import { mediaNews } from '@/data/newsroom.js';
import { sortMediaNews } from '@/helpers/Utils.js';

export const mainSection1Mixin = {
    data() {
        let self = this;
        let origin = -75,
            interval = 15;
        return {
            countries: [
                {
                    name: 'sgp',
                    location: { lat: 1.3521, lng: 103.8198 },
                    icon: require('@/assets/main/sgp.png'),
                    top: self.calcPointsInCircle(origin).top,
                    right: self.calcPointsInCircle(origin).right
                },
                {
                    name: 'chn',
                    location: { lat: 39.9042, lng: 116.4074 },
                    icon: require('@/assets/main/chn.png'),
                    top: self.calcPointsInCircle(origin + interval).top,
                    right: self.calcPointsInCircle(origin + interval).right
                },
                {
                    name: 'hkg',
                    location: { lat: 22.3193, lng: 114.1694 },
                    icon: require('@/assets/main/hkg.png'),
                    top: self.calcPointsInCircle(origin + 2 * interval).top,
                    right: self.calcPointsInCircle(origin + 2 * interval).right
                },
                {
                    name: 'jpn',
                    location: { lat: 35.6762, lng: 139.6503 },
                    icon: require('@/assets/main/jpn.png'),
                    top: self.calcPointsInCircle(origin + 3 * interval).top,
                    right: self.calcPointsInCircle(origin + 3 * interval).right
                },
                {
                    name: 'kor',
                    location: { lat: 37.5665, lng: 126.978 },
                    icon: require('@/assets/main/kor.png'),
                    top: self.calcPointsInCircle(origin + 4 * interval).top,
                    right: self.calcPointsInCircle(origin + 4 * interval).right
                },
                {
                    name: 'tha',
                    location: { lat: 13.7563, lng: 100.5018 },
                    icon: require('@/assets/main/tha.png'),
                    top: self.calcPointsInCircle(origin + 5 * interval).top,
                    right: self.calcPointsInCircle(origin + 5 * interval).right
                },
                {
                    name: 'mys',
                    location: { lat: 3.139, lng: 101.6869 },
                    icon: require('@/assets/main/mys.png'),
                    top: self.calcPointsInCircle(origin + 6 * interval).top,
                    right: self.calcPointsInCircle(origin + 6 * interval).right
                },
                {
                    name: 'phl',
                    location: { lat: 14.5995, lng: 120.9842 },
                    icon: require('@/assets/main/phl.png'),
                    top: self.calcPointsInCircle(origin + 7 * interval).top,
                    right: self.calcPointsInCircle(origin + 7 * interval).right
                },
            ]
        };
    },
    methods: {
        // O(x0, y0)
        // r
        // x1 = x0 + r * cos(angle * PI / 180)
        // y1 = y0 + r * sin(angle * PI /180)
        calcPointsInCircle(angle) {
            let o = { x: 71.4, y: 24.2 };
            let r = 22;
            if (this.$vuetify.breakpoint.mobile) {
                o = { x: 52.4, y: 156.2 };
                r = 42;
            }
            const x1 = o.x + r * Math.cos((angle * Math.PI) / 180);
            const y1 = o.y + r * Math.sin((angle * Math.PI) / 180);
            return {
                top: `${y1}vw`,
                right: 100 - x1 + `vw`
            };
        }
    },
    created() {
        this.hoverCountry = debounce(location => {
            world.pointOfView(location, 1000);
        }, 500);
    },
    mounted() {
        this.$nextTick(() => {
            const container = document.querySelector('#evo-globe-wrapper');
            world.appendTo(container);
        });
    }
};

export const mainSection2Mixin = {
    methods: {
        goTo(link) {
            this.$router.push(link).catch(() => { });
        }
    },
    computed: {
        dynamicHeight() {
            const winWidth = this.$vuetify.breakpoint.width;
            const newWidth = parseInt((320 / 1080) * winWidth);
            // phone
            if (winWidth < 600) {
                return 540;
            }

            // pad
            if (winWidth < 960) {
                return parseInt((860 / 960) * winWidth);
            }

            return Math.min(newWidth, 540);
        },
        slides() {
            return mediaNews.items.sort(sortMediaNews).slice(0, 4);
        }
    }
};