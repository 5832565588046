import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: require("@/i18n/locale/en.json"),
    zh: require("@/i18n/locale/zh.json"),
  },
});

export default i18n;
