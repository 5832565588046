<template>
  <div class="section-2 evo-mobile evo-pv-120">
    <v-row class="mx-0">
      <v-col class="px-0">
        <div class="evo-quote-mark">
          <img loading="lazy" src="@/assets/quote-mark.png" alt="evo-quote-mark" />
        </div>
        <v-hover v-slot="{ hover }">
          <v-lazy :options="{ threshold: 0.01 }">
            <v-carousel hide-delimiter-background :show-arrows="false" :cycle="!hover" interval="3000" height="146vw">
              <v-carousel-item v-for="(item, index) in slides" :key="index">
                <v-row class="mx-0" justify="center">
                  <v-col class="px-0" align-self="center" cols="12">
                    <div class="evo-title evo-text-32">{{ $t(item.title) }}</div>
                    <div class="evo-sub-title evo-text-24 evo-opensans-regular">
                      {{ $t(item.subtitle) }}
                    </div>
                    <v-btn rounded x-large color="default" class="text-none evo-entry-btn" @click="goTo(item.link)">
                      <span class="evo-text-30">{{ $t('main_entry.read_more') }}</span>
                    </v-btn>
                  </v-col>
                  <v-col class="px-0" align-self="center" cols="12">
                    <img class="evo-img-auto" :src="item.smallImg" :alt="item.title" />
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-lazy>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mainSection2Mixin } from '@/mixins/main.js';
export default {
  name: 'EvoMMainSectionTwo',
  mixins: [mainSection2Mixin]
};
</script>
<style lang="scss" scoped>
.section-2 {
  background: #fafafa;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);

  ::v-deep(.v-item-group) {
    .v-carousel__controls {
      justify-content: flex-start;
      left: 0px !important;
    }

    .v-btn {
      color: #767a80;
    }

    .v-item--active {
      color: #589aff;
    }

    .evo-entry-btn {
      @include whiteblue_btn;
      color: #3b43f2;
      min-width: 308px;
      margin-bottom: 24px;
    }
  }

  .evo-quote-mark {
    margin-left: 12px;
    width: 100%;
    margin-bottom: 48px;
  }

  .evo-title {
    color: #222a41;
    margin-bottom: 32px;
  }

  .evo-sub-title {
    color: #222a41;
    margin-bottom: 64px;
  }

  .evo-slide-img {
    width: 100%;
  }
}
</style>
