<template>
  <div class="evo-footer-wrapper" :class="{ 'mt-self': !isPageContactUs }">
    <v-lazy :options="{ threshold: 0.01 }">
      <div v-if="isPageContactUs" class="evo-contact-pane-self"></div>
      <div v-if="!isPageContactUs" class="evo-contact-pane" data-aos="zoom-in-right">
        <router-link to="contact-us" class="router-link evo-contact-us">
          <v-btn rounded x-large class="text-none evo-contact-btn">
            {{ $t('home.learn_more') }}
          </v-btn>
        </router-link>
        <div class="evo-contact-title evo-text-25 evo-opensans-extrabold">
          {{ $t('contact_title') }}
        </div>
        <div class="evo-contact-sub-title evo-text-16 evo-opensans-regular">
          {{ $t('contact_sub_title') }}
        </div>
      </div>
    </v-lazy>
    <div class="evo-footer-items">
      <div class="evo-logo-info" :class="{ 'evo-width-20': $vuetify.breakpoint.xlOnly }">
        <div class="evo-logo-wrapper">
          <img loading="lazy" class="evo-px-logo" src="@/assets/evonet-logo.svg" alt="evo-px-logo" />
          <div class="evo-logo-desc-wrapper">
            <div class="evo-px-logo-desc">{{ $t('global') }}</div>
            <div class="evo-px-logo-desc">
              {{ $t('wallet_network_aggregator') }}
            </div>
            <div class="evo-px-logo-desc">
              {{ $t('shared_distributed_and_open_network') }}
            </div>
          </div>
        </div>
        <div class="evo-find-us evo-opensans-regular">
          {{ $t('find_us') }}
        </div>
        <div class="evo-email">{{ 'contact@evonetglobal.com' }}</div>
        <!-- <div class="evo-phone">{{ "(65) 62791630" }}</div> -->
        <div class="evo-follow-us evo-opensans-regular">
          {{ $t('follow_us') }}
        </div>
        <div class="evo-mdi-icon">
          <a href="https://www.facebook.com/Evonet-Global-Pte-Ltd-110368611698374" target="_blank" class="text-decoration-none">
            <v-icon color="white"> $vuetify.icons.mdiFacebook </v-icon>
          </a>
          <a href="https://twitter.com/EvonetGlobal" target="_blank" class="text-decoration-none">
            <v-icon color="white"> $vuetify.icons.mdiTwitter </v-icon>
          </a>
        </div>
      </div>
      <div>
        <div class="evo-item-solutions evo-opensans-extrabold">
          {{ $t('solutions') }}
        </div>
      </div>
      <div>
        <div class="evo-item-for-merchants">
          <router-link to="/merchant-services" class="router-link evo-title">
            {{ $t('merchant_services') }}
          </router-link>
          <div class="evo-header">{{ $t('product') }}</div>
          <div class="evo-header-link">
            <router-link to="/online-merchant-acquiring" class="router-link">
              <span class="evo-nav-item">
                {{ $t('online_merchant_acquiring') }}
              </span>
            </router-link>
          </div>
          <div class="evo-header">{{ $t('feature') }}</div>
          <div class="evo-header-link">
            <router-link to="/payment-methods" class="router-link">
              <span class="evo-nav-item">
                {{ $t('payment_methods.title') }}
              </span>
            </router-link>
          </div>
          <div class="evo-header-link">
            <router-link to="/fraud-protection" class="router-link">
              <span class="evo-nav-item">
                {{ $t('fraud_protection') }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <div class="evo-item-payment">
          <router-link to="/wallet-network" class="router-link evo-title">
            {{ $t('home.wallet_network') }}
          </router-link>
          <div class="evo-header">{{ $t('product') }}</div>
          <div class="evo-header-link">
            <router-link to="/cross-border-e-payment" class="router-link">
              <span class="evo-nav-item">
                {{ $t('cross_border_e_payment') }}
              </span>
            </router-link>
          </div>
          <div class="evo-header-link">
            <router-link to="/inter-wallet-remittance" class="router-link">
              <span class="evo-nav-item">
                {{ $t('inter_wallet_remittance') }}
              </span>
            </router-link>
          </div>
          <div class="evo-header">{{ $t('feature') }}</div>
          <div class="evo-header-link">
            <router-link to="/fx-rate-convertor" class="router-link">
              <span class="evo-nav-item">
                {{ $t('fx_rate_convertor') }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <!-- company -->
      <div>
        <div class="evo-item-evonet evo-opensans-extrabold">
          {{ $t('company') }}
        </div>
        <div class="evo-item-evonet evo-documents evo-opensans-extrabold">
          {{ $t('documents') }}
        </div>
      </div>
      <div>
        <div class="evo-evonet-items">
          <div class="evo-header-link" v-for="item in [...companyItems, ...documentsItems]" :key="item.path">
            <template v-if="item.path">
              <router-link :to="item.path" class="router-link">
                <span class="evo-nav-item">
                  {{ $t(item.title) }}
                </span>
              </router-link>
            </template>
            <template v-else>
              <a :href="item.href" target="_blank" class="text-decoration-none">
                <span class="evo-nav-item">
                  {{ $t(item.title) }}
                </span>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- privacy -->
    <div class="evo-privacy-wrapper evo-opensans-regular pb-6">
      <div class="evo-copyright"> ©️ {{ year }} EVONET Global. All rights reserved. </div>
      <router-link class="router-link evo-privacy-item" v-for="item in privacyItems" :to="item.path" :key="item.path">
        <span class="evo-nav-item">
          {{ $t(item.text) }}
        </span>
      </router-link>
    </div>
    <div class="evo-bg"></div>
  </div>
</template>

<script>
import { footerMixin } from '@/mixins/components/footer.js';
export default {
  name: 'EvoFooter',
  mixins: [footerMixin]
};
</script>

<style lang="scss" scoped>
$color-white-600: rgba(
  $color: #ffffff,
  $alpha: 0.6
);
$text-line-height: 16px;
$title-line-height: 26px;
.evo-footer-wrapper {
  padding: 0 84px;
  position: relative;

  &.mt-self {
    margin-top: 148px;
  }

  .evo-nav-item {
    color: white !important;
    white-space: nowrap;
    height: $text-line-height;
    line-height: $text-line-height;
    &:hover {
      text-decoration: underline;
    }
  }

  .evo-contact-pane-self {
    height: 120px;
  }

  .evo-contact-pane {
    position: relative;
    z-index: 1;
    background-image: url('~@/assets/footer-contact-pane.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 672px;
    height: 280px;
    margin: 0 auto;
    margin-top: -100px;

    .evo-contact-us {
      position: absolute;
      left: 47px;
      bottom: 90px;
      z-index: 1;

      .evo-contact-btn {
        @include lightblue_btn;
        width: 130px;
      }
    }

    .evo-contact-title {
      position: absolute;
      left: 232px;
      top: 50px;
      font-weight: 600;
      color: #000000;
      line-height: 30px;
    }

    .evo-contact-sub-title {
      position: absolute;
      left: 232px;
      top: 108px;
      width: 420px;
      color: #a0a3a8;
      line-height: 30px;
    }
  }

  .evo-bg {
    background-image: url('~@/assets/footer-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    bottom: 0;
    z-index: 0;
  }

  .evo-footer-items {
    position: relative;
    z-index: 1;
    margin-top: 60px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & > div {
      display: inline-block;
    }

    .evo-width-20 {
      width: 20% !important;
    }

    & > div:first-child {
      width: 30%;
    }

    & > :nth-child(2) {
      width: 7%;
    }

    & > :nth-child(3) {
      width: 14%;
    }

    & > :nth-child(4) {
      width: 18%;
    }

    & > :nth-child(5) {
      width: 9%;
    }

    & > :nth-child(6) {
      width: 6%;
    }

    .evo-logo-info {
      display: flex;
      flex-direction: column;

      & > div {
        margin-bottom: 12px;
      }

      .evo-logo-wrapper {
        display: flex;
      }

      .evo-px-logo {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }

      .evo-logo-desc-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .evo-px-logo-desc {
          font-size: 12px;
          margin-bottom: 2px;
          text-align: left;
        }
      }

      .evo-find-us {
        font-size: 10px;
        font-weight: normal;
        color: $color-white-600;
      }

      .evo-email {
        font-size: 10px;
        font-weight: 600;
        color: #ffffff;
      }

      .evo-phone {
        font-size: 10px;
        font-weight: 600;
        color: #ffffff;
      }

      .evo-follow-us {
        font-size: 10px;
        font-weight: normal;
        color: $color-white-600;
      }

      .evo-mdi-icon {
        & > i {
          font-size: 24px;
        }
        a + a {
          margin-left: 24px;
        }
      }
    }

    .evo-item-solutions {
      font-size: 13px;
      color: $color-white-600;
      height: $title-line-height;
      line-height: $title-line-height;
      text-align: right;
    }

    .evo-item-payment,
    .evo-item-for-merchants {
      display: flex;
      flex-direction: column;
      .evo-title {
        font-style: oblique;
        color: $color-white-600;
        height: $title-line-height;
        line-height: $title-line-height;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .evo-item-payment,
    .evo-item-for-merchants {
      & > div {
        margin-bottom: 8px;
      }

      .evo-title {
        font-size: 13px;
        color: #ffffff;
      }

      .evo-header {
        font-size: 9px;
        color: $color-white-600;
        font-weight: 400;
        height: $text-line-height;
        line-height: $text-line-height;
      }

      .evo-header-link {
        font-size: 9px;
        color: #ffffff;
        font-family: 'OpenSans-Regular';
        font-weight: 400;
        white-space: nowrap;
        height: $text-line-height;
        line-height: $text-line-height;
      }
    }

    .evo-evonet-items {
      display: flex;
      flex-direction: column;

      .evo-header-link {
        font-size: 9px;
        color: #ffffff;
        font-family: 'OpenSans-Regular';
        font-weight: 400;
        white-space: nowrap;
        height: $text-line-height;
        line-height: $text-line-height;
        margin-bottom: 8px;
        &:nth-child(1) {
          height: $title-line-height;
          line-height: $title-line-height;
          margin-bottom: 0;
        }
      }
    }

    .evo-item-evonet {
      font-size: 13px;
      color: $color-white-600;
      height: $title-line-height;
      line-height: $title-line-height;
      text-align: right;
    }

    .evo-documents {
      margin-top: 66px;
    }
  }

  .evo-privacy-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    margin-top: 48px;
    font-size: 9px;
    color: #ffffff;

    .evo-copyright {
      font-size: 12px;
      line-height: 23px;
    }
  }
}
</style>
