import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/wallet-network',
    name: 'WalletNetwork',
    component: () => import('../views/WalletNetwork.vue')
  },
  {
    path: '/merchant-services',
    name: 'MerchantServices',
    component: () => import('../views/MerchantServices.vue')
  },
  {
    path: '/cross-border-e-payment',
    name: 'Payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/inter-wallet-remittance',
    name: 'Transfer',
    component: () => import('../views/Transfer.vue')
  },
  // {
  //   path: "/debit",
  //   name: "Debit",
  //   component: () => import("../views/Debit.vue"),
  // },
  // {
  //   path: "/partners",
  //   name: "Partners",
  //   component: () => import("../views/Partners.vue"),
  // },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/fx-rate-convertor',
    name: 'FXRate',
    component: () => import('../views/CurrencyExchangeCalculator.vue')
  },
  {
    path: '/company-policy',
    component: () => import('../views/legal/index.vue'),
    children: [
      {
        path: '',
        name: 'Legal',
        component: () => import('../views/legal/Home.vue')
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/legal/Detail.vue')
      },
      {
        path: 'acceptable-use-policy',
        name: 'AcceptableUsePolicy',
        component: () => import('../views/legal/Detail.vue')
      },
      {
        path: 'data-protection-policy',
        name: 'DataProtectionPolicy',
        component: () => import('../views/legal/Detail.vue')
      },
      {
        path: 'prohibited-goods-services',
        name: 'ProhibitedGoodsServices',
        component: () => import('../views/legal/Detail.vue')
      }
    ]
  },
  {
    path: '/fraud-protection',
    name: 'FraudProtect',
    component: () => import('../views/FraudProtect.vue')
  },
  {
    path: '/partnership',
    name: 'Partnership',
    component: () => import('../views/Partnership.vue')
  },
  {
    path: '/online-merchant-acquiring',
    name: 'OnlineMerchantAcquiring',
    component: () => import('../views/OnlineMerchantAcquiring.vue')
  },
  {
    path: '/newsroom',
    component: () => import('../views/newsroom/index.vue'),
    children: [
      {
        path: '',
        name: 'Newsroom',
        component: () => import('../views/newsroom/Home.vue')
      },
      {
        path: ':id',
        name: 'NewsroomDetail',
        component: () => import('../views/newsroom/Detail.vue')
      }
    ]
  },
  {
    path: '/payment-methods',
    component: () => import('../views/payment_methods/index.vue'),
    children: [
      {
        path: '',
        name: 'PaymentMethods',
        component: () => import('../views/payment_methods/Home.vue')
      },
      {
        path: ':brand',
        name: 'PaymentMethodsDetail',
        component: () => import('../views/payment_methods/Detail.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return window.scrollTo(0, scrollTo);
  },
  routes
});

export default router;
