import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import i18n from "@/i18n/i18n";
import {
  mdiArrowUp,
  mdiFacebook,
  mdiTwitter,
  mdiChevronDown,
  mdiChevronRight,
  mdiArrowRight,
  mdiEarth,
  mdiLoginVariant,
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
  mdiCreditCardOutline,
  mdiCogTransferOutline,
  mdiWalletTravel,
  mdiInformationOutline,
  mdiHandshakeOutline,
  mdiCardAccountPhoneOutline
} from '@mdi/js';

const MDI_ICONS = {
  mdiArrowUp,
  mdiFacebook,
  mdiTwitter,
  mdiChevronDown,
  mdiChevronRight,
  mdiArrowRight,
  mdiEarth,
  mdiLoginVariant,
  mdiCloseCircleOutline,
  mdiCheckCircleOutline,
  mdiCreditCardOutline,
  mdiCogTransferOutline,
  mdiWalletTravel,
  mdiInformationOutline,
  mdiHandshakeOutline,
  mdiCardAccountPhoneOutline
};

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: MDI_ICONS
  },
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params),
  // },
  breakpoint: {
    mobileBreakpoint: 600
  },
  theme: {
    themes: {
      light: {
        primary: '#2568FF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    }
  }
});
