<template>
  <div class="evo-navigation-items-wrapper">
    <!-- category -->
    <div class="evo-category-wrapper">
      <v-expansion-panels accordion flat tile>
        <!-- Home -->
        <v-expansion-panel readonly>
          <v-expansion-panel-header hide-actions>
            <div class="panel-title">
              <router-link class="router-link" to="/">
                <span class="evo-nav-item">{{ $t('main') }}</span>
              </router-link>
            </div>
          </v-expansion-panel-header>
        </v-expansion-panel>
        <!-- solutions -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="panel-title">{{ $t('solutions') }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="evo-item-for-merchants">
              <router-link to="/merchant-services" class="router-link evo-title">
                {{ $t('merchant_services') }}
              </router-link>
              <div class="evo-header evo-opensans-regular">{{ $t('product') }}</div>
              <div class="evo-header-link">
                <router-link to="/online-merchant-acquiring" class="router-link">
                  <span class="evo-nav-item">
                    {{ $t('online_merchant_acquiring') }}
                  </span>
                </router-link>
              </div>
              <div class="evo-header evo-opensans-regular">{{ $t('feature') }}</div>
              <div class="evo-header-link">
                <router-link to="/payment-methods" class="router-link">
                  <span class="evo-nav-item">
                    {{ $t('payment_methods.title') }}
                  </span>
                </router-link>
              </div>
              <div class="evo-header-link">
                <router-link to="/fraud-protection" class="router-link">
                  <span class="evo-nav-item">
                    {{ $t('fraud_protection') }}
                  </span>
                </router-link>
              </div>
            </div>
            <div class="evo-item-payment">
              <router-link to="/wallet-network" class="router-link evo-title">
                {{ $t('home.wallet_network') }}
              </router-link>
              <div class="evo-header evo-opensans-regular">{{ $t('product') }}</div>
              <div class="evo-header-link">
                <router-link to="/cross-border-e-payment" class="router-link">
                  <span class="evo-nav-item">
                    {{ $t('cross_border_e_payment') }}
                  </span>
                </router-link>
              </div>
              <div class="evo-header-link">
                <router-link to="/inter-wallet-remittance" class="router-link">
                  <span class="evo-nav-item">
                    {{ $t('inter_wallet_remittance') }}
                  </span>
                </router-link>
              </div>
              <div class="evo-header evo-opensans-regular">{{ $t('feature') }}</div>
              <div class="evo-header-link">
                <router-link to="/fx-rate-convertor" class="router-link">
                  <span class="evo-nav-item">
                    {{ $t('fx_rate_convertor') }}
                  </span>
                </router-link>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- company / documents -->
        <v-expansion-panel v-for="group in mergeItems" :key="group.title">
          <v-expansion-panel-header>
            <div class="panel-title">{{ $t(group.title) }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="evo-evonet-items">
              <div class="evo-header-link" v-for="item in group.children" :key="item.path">
                <template v-if="item.path">
                  <router-link :to="item.path" class="router-link">
                    <span class="evo-nav-item">
                      {{ $t(item.title) }}
                    </span>
                  </router-link>
                </template>
                <template v-else>
                  <div class="d-flex align-center justify-space-between" @click="goHref(item.href)">
                    <span class="evo-nav-item">
                      {{ $t(item.title) }}
                    </span>
                    <v-icon color="white" v-if="item.icon"  v-text="item.icon"></v-icon>
                  </div>
                </template>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- login (只用在侧边栏)-->
        <v-expansion-panel v-if="!showPrivacy">
          <v-expansion-panel-header>
            <div class="panel-title">{{ $t('login') }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="evo-login-items">
              <div v-for="(item, index) in loginItems" :key="index">
                <div class="d-flex align-center justify-space-between" @click="goHref(item.href)">
                  <span class="evo-px-33">
                    {{ $t(item.title) }}
                  </span>
                  <v-icon v-text="item.icon"></v-icon>
                </div>
                <div class="evo-link-item-desc evo-px-29 evo-opensans-regular">
                  {{ $t(item.desc) }}
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Touch (只用在侧边栏)-->
    <router-link to="/contact-us" class="router-link"  v-if="!showPrivacy">
      <div class="evo-get-touch">
        <v-btn rounded block class="text-none">
          {{ $t('get_in_touch') }}
        </v-btn>
      </div>
    </router-link>
    <!-- privacy (只用在底部导航栏)-->
    <div class="evo-privacy-wrapper evo-opensans-regular" v-if="showPrivacy">
      <div class="evo-privacy-item" v-for="item in privacyItems" :key="item.path">
        <router-link class="router-link" :to="item.path">
          <span class="evo-nav-item">
            {{ $t(item.title) }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvoNavigationItems',
  props: {
    showPrivacy: {
      type: Boolean,
      default: true
    },
    companyItems: [],
    documentsItems: [],
    loginItems: []
  },
  data() {
    return {
      privacyItems: [
        {
          path: '/company-policy/privacy-policy',
          title: 'legal.title_1'
        },
        {
          path: '/company-policy/acceptable-use-policy',
          title: 'legal.title_2'
        },
        {
          path: '/company-policy/data-protection-policy',
          title: 'legal.title_3'
        },
        {
          path: '/company-policy/prohibited-goods-services',
          title: 'legal.title_4'
        }
      ],
      mergeItems: [
        {
          title: 'company',
          children: this.companyItems
        },
        {
          title: 'documents',
          children: this.documentsItems
        }
      ]
    };
  },
  methods: {
    // 外链跳转
    goHref(href) {
      if (!href) return;
      window.open(href, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
$color-white-600: rgba(
  $color: #ffffff,
  $alpha: 0.6
);
.evo-nav-item {
  color: white !important;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
}
.evo-category-wrapper {
  margin-bottom: 26px;
  .theme--light.v-expansion-panels .v-expansion-panel {
    color: #ffffff;
    background: transparent;
  }
  .theme--light.v-expansion-panels .v-expansion-panel-header ::v-deep(.v-expansion-panel-header__icon .v-icon) {
    color: #ffffff;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel {
    color: #ffffff;
    background: transparent;
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: 120px;
    border-bottom: 2px solid #ffffff;
    &.v-expansion-panel-header--active {
      border: none;
    }
  }

  ::v-deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
  }

  .panel-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 56px;
  }

  .evo-item-payment,
  .evo-item-for-merchants {
    display: flex;
    flex-direction: column;

    .evo-title {
      font-style: oblique;
      font-size: 38px;
      font-weight: normal;
      line-height: 90px;
      color: $color-white-600;
      margin-bottom: 22px;
      &:hover {
        text-decoration: underline;
      }
    }

    .evo-header {
      font-size: 29px;
      line-height: 54px;
      color: $color-white-600;
      margin-bottom: 22px;
    }

    .evo-header-link {
      font-size: 33px;
      font-weight: 800;
      line-height: 54px;
      margin-bottom: 22px;
    }
  }

  .evo-item-for-merchants {
    margin-bottom: 25px;
  }

  .evo-evonet-items {
    display: flex;
    flex-direction: column;

    .evo-header-link {
      font-size: 33px;
      font-weight: 800;
      line-height: 54px;
      margin-bottom: 26px;
    }
  }
}

.evo-login-items {
  display: flex;
  flex-direction: column;

  .evo-link-item-desc {
    padding: 28px 16px 22px 8px;
    line-height: 54px;
    color: $color-white-600;
  }
}

.evo-get-touch {
  position: absolute;
  padding: 48px 32px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #363636;
  z-index: 1;
  .v-btn {
    font-size: 32px;
    background-color: #3b43f2;
  }
}

.evo-privacy-wrapper {
  margin-bottom: 54px;
  .evo-privacy-item {
    font-size: 33px;
    line-height: 54px;
    & + .evo-privacy-item {
      margin-top: 26px;
    }
  }
}
</style>
