<template>
  <v-app>
    <EvoNavigation :flat="flat" />
    <v-main>
      <router-view />
    </v-main>
    <v-scale-transition>
      <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>$vuetify.icons.mdiArrowUp</v-icon>
      </v-btn>
    </v-scale-transition>
    <EvoMobileFooter v-if="$vuetify.breakpoint.mobile" />
    <EvoFooter v-else />
    <FBChat />
    <v-snackbar v-model="snackbar.value" :timeout="snackbar.timeout" top :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
import EvoNavigation from '@/components/Navigation.vue';
import EvoFooter from '@/components/Footer.vue';
import EvoMobileFooter from '@/mobile/components/Footer.vue';
import FBChat from '@/components/FBChat.vue';
export default {
  name: 'App',
  components: { EvoNavigation, EvoFooter, EvoMobileFooter, FBChat },
  // components: { EvoNavigation, EvoFooter, EvoMobileFooter },
  data: () => ({
    fab: null,
    flat: null,
    snackbar: {
      value: false,
      color: 'warning',
      message: '',
      timeout: 3000
    }
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.flat = true;
    }

    window.addEventListener('unhandledrejection', this.onUnhandledRejection, false);
  },

  // add destroy life cycle hook
  beforeDestroy() {
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
  },

  watch: {
    fab(value) {
      this.flat = !value;
    }
  },

  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    onUnhandledRejection(err) {
      console.log(err);
      this.snackbar.value = true;
      // this.snackbar.message = err.reason;
      // Always set a generic message
      this.snackbar.message = this.$t('general_err');
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::v-deep(.v-application--wrap) {
  background: $header_bg_color !important;
}
</style>
